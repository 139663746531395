<script setup>
import { WdsItemPicker } from '@wds/components';
import { useCurrencyStore } from '~/stores/currency';
import { CURRENCIES, DEFAULT_CURRENCY } from '~/config/currencies';

const { t } = useI18n();
const { isBelowMediumScreen } = useMq();

const currencyStore = useCurrencyStore();

const getCurrencyList = computed(() => {
  return CURRENCIES.map((currency) => ({
    label: `${t(currency.transcode)} (${currency.code})`,
    value: currency.code,
  }));
});

async function handleCurrencySelected(currency) {
  const selectedCurrency = currency || DEFAULT_CURRENCY.code;
  await currencyStore.setCurrency(selectedCurrency);
  navigateTo(document.location.href, { external: true });
}
</script>

<template>
  <div class="currency-picker">
    <WdsItemPicker
      :is-open="currencyStore.isCurrencyPickerOpen"
      :heading="$t('t_CURRENCY')"
      :items="getCurrencyList"
      :selected="currencyStore.getCurrentCurrency?.code"
      :is-modal="!toValue(isBelowMediumScreen)"
      :search-bar="true"
      @close-item-picker="currencyStore.toggleCurrencyPicker(false)"
      @item-selected="handleCurrencySelected"
    />
  </div>
</template>
